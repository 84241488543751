import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useCallback, useEffect } from 'react';
import { useUniversalLoader } from '@zolak/zolak-viewer';
import useMaterials from './useMaterials';

const ZOOM_RATIO = 1.28;

const getZoom = (size) => {
  const max = Math.max(size.x, size.z);
  const min = Math.min(size.x, size.z);
  const ratio = size.z * (max / min) * ZOOM_RATIO;
  return size.z + ratio;
};

const useLoadObject = (value, materials = [], scale = [1, 1, 1], size, onLoad = () => {}) => {
  const { camera, gl, scene, controls } = useThree();
  const object = useUniversalLoader({
    ...value,
    data: value.data && size ? `${value.data}?size=${size}` : value.data,
    blob: value.blob && size ? `${value.blob}?size=${size}` : value.blob,
  });

  if (!object.userData.originalMaterials) {
    camera.position.x = -2;
    camera.position.y = 2;
    object.userData.originalMaterials = {};
    object.traverse((element) => {
      if (element.isMesh) {
        object.userData.originalMaterials[element.uuid] = element.material;
      }
    });
  }

  useMaterials(object, scale, materials, size, value.data || value.blob);

  const handleLoad = useCallback((size) => {
    const originalSize = new THREE.Vector2();
    const originalAspect = camera.aspect;
    gl.getSize(originalSize);
    camera.aspect = 1;
    camera.updateProjectionMatrix();
    gl.setSize(512, 512);
    gl.render(scene, camera);
    const thumbnail = gl.domElement.toDataURL();
    camera.aspect = originalAspect;
    camera.updateProjectionMatrix();
    gl.setSize(originalSize.x, originalSize.y);
    gl.render(scene, camera);
    onLoad({
      object,
      thumbnail,
      size: {
        width: size.x,
        height: size.y,
        length: size.z,
      },
    });
  }, [gl, scene, camera, object, onLoad]);
  useEffect(() => {
    if (object && camera && controls) {
      const box = new THREE.Box3();
      box.setFromObject(object, true);

      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());
      object.position.x += object.position.x - center.x;
      object.position.y += object.position.y - center.y;
      object.position.z += object.position.z - center.z;

      camera.position.set(-2, 2, getZoom(size));
      controls.update();

      handleLoad(size.divide(object.scale));
    }

    return () => {
      object.position.x = 0;
      object.position.y = 0;
      object.position.z = 0;
    };
  }, [object, camera, controls]);

  return object;
};

export default useLoadObject;
