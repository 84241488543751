export const AVAILABLE_LANGUAGES = [
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'de',
    label: 'German',
  },
  {
    key: 'fr',
    label: 'French',
  },
  {
    key: 'es',
    label: 'Spanish',
  },
];

export const translationsToFields = (translations) => {
  const translatedFields = {};
  translations?.forEach((translation) => {
    translatedFields[translation.lang] = {};
    Object
      .keys(translation)
      .filter((key) => key !== 'lang')
      .forEach((propName) => {
        translatedFields[translation.lang][propName] = translation[propName];
      });
  });
  return translatedFields;
};

export const translatedFieldsToArray = (translatedFields) => {
  const translations = [];
  Object
    .keys(translatedFields)
    .forEach((lang) => {
      translations.push({
        lang,
        ...translatedFields[lang],
      });
    });
  return translations;
};
