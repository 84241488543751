import {
  convertFileToBase64,
  convertMillimetersToMeters,
  convertMetersToMillimeters,
  withDefaultModelSize,
} from '@shared/utils';

const ENDPOINT = '/models';

class ModelsApi {
  constructor(http) {
    this.http = http;
  }

  getModels = async () => {
    const data = await this.http.get(ENDPOINT);

    return {
      ...data,
      items: data.items.map((model) => ({
        ...model,
        width: convertMetersToMillimeters(withDefaultModelSize(model.width)).toString(),
        height: convertMetersToMillimeters(withDefaultModelSize(model.height)).toString(),
        length: convertMetersToMillimeters(withDefaultModelSize(model.length)).toString(),
      })),
    };
  }

  getModelsCounts = () => this.http.get(`${ENDPOINT}/counts`)

  getModelScenes = (id) => this.http.get(`${ENDPOINT}/${id}/scenes`)

  createModel = async (model) => {
    const { file: image, blob, dataType } = model.data;
    const thumbnail = model?.thumbnail || model.data.thumbnail;
    const data = await this.http.post(ENDPOINT, {
      data: blob,
      dataType,
      name: model.name,
      width: convertMillimetersToMeters(withDefaultModelSize(model.width)).toString(),
      height: convertMillimetersToMeters(withDefaultModelSize(model.height)).toString(),
      length: convertMillimetersToMeters(withDefaultModelSize(model.length)).toString(),
      scale: model.scale,
      type: model.type,
      attributes: model.attributes,
    });

    if (image) {
      const picture64 = await convertFileToBase64(image);

      const imageData = await this.http.post(`${ENDPOINT}/${data.id}/upload`, {
        src: picture64,
        title: image.name,
        type: image.type,
        thumbnail,
      });

      data.data = imageData.data;
    }

    return data;
  }

  editModel = async (model) => {
    const { file: image, thumbnail, dataType } = model.data;

    const data = await this.http.put(`${ENDPOINT}/${model.id}`, {
      id: model.id,
      dataType: dataType || model.dataType,
      name: model.name,
      width: convertMillimetersToMeters(withDefaultModelSize(model.width)).toString(),
      height: convertMillimetersToMeters(withDefaultModelSize(model.height)).toString(),
      length: convertMillimetersToMeters(withDefaultModelSize(model.length)).toString(),
      scale: model.scale,
      type: model.type,
      attributes: model.attributes,
    });

    if (image) {
      const picture64 = await convertFileToBase64(image);

      const imageData = await this.http.post(`${ENDPOINT}/${data.id}/upload`, {
        src: picture64,
        title: image.name,
        type: image.type,
        thumbnail,
      });

      data.data = imageData.data;
    } else if (thumbnail) {
      const imageData = await this.http.post(`${ENDPOINT}/${data.id}/thumbnail`, {
        thumbnail,
      });

      data.thumbnail = imageData.thumbnail;
    }

    return data;
  }

  deleteModel = (id) => this.http.delete(`${ENDPOINT}/${id}`)

  markModelAsDefaultContent = (id) => this.http.put(`${ENDPOINT}/${id}/markAsDefaultContent`)

  unmarkModelAsDefaultContent = (id) => this.http.put(`${ENDPOINT}/${id}/unmarkAsDefaultContent`)

  hideModel = (id) => this.http.put(`${ENDPOINT}/${id}/hide`)
}

export default ModelsApi;
