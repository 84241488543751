import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { modelsApi } from '@api';
import {
  LOAD_MODELS_LIST,
  LOAD_MODELS_COUNTS,
  LOAD_MODEL_SCENES,
  CREATE_MODEL,
  UPDATE_MODEL,
  DELETE_MODEL,
  renderModelsList,
  renderModelsCounts,
  putModelScenes,
  renderModel,
  MARK_MODEL_AS_DEFAULT_CONTENT,
  UNMARK_MODEL_AS_DEFAULT_CONTENT,
  HIDE_MODEL,
  loadModelsList,
  deleteModelError,
  createModelSuccess,
  updateModelSuccess,
  createModelError,
  updateModelError,
} from './actions';
import { selectCurrentCompany } from '@store/app/selectors';
import addHiddenStateForItems from '@shared/utils/addHiddenStateForItems';

export function* fetchModelsList() {
  try {
    const data = yield call(modelsApi.getModels);
    const company = yield select(selectCurrentCompany);
    yield put(renderModelsList(addHiddenStateForItems(data, company)));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchModelsCounts() {
  try {
    const data = yield call(modelsApi.getModelsCounts);
    yield put(renderModelsCounts(data));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchModelScenes({ id }) {
  try {
    const data = yield call(modelsApi.getModelScenes, id);
    yield put(putModelScenes(data));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchPostModel({ model }) {
  try {
    const data = yield call(modelsApi.createModel, model);
    yield put(renderModel(data));
    yield put(loadModelsList());
    yield put(createModelSuccess());
  } catch (error) {
    if (error.response?.data) {
      yield put(createModelError(error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* fetchPutModel({ model }) {
  try {
    const data = yield call(modelsApi.editModel, model);
    yield put(renderModel(data));
    yield put(loadModelsList());
    yield put(updateModelSuccess());
  } catch (error) {
    if (error.response?.data) {
      yield put(updateModelError(error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* fetchDeleteModel({ id }) {
  try {
    yield call(modelsApi.deleteModel, id);
    yield put(loadModelsList());
  } catch (error) {
    if (error.response?.data) {
      yield put(deleteModelError(id, error.response?.data));
      return;
    }
    console.error(error);
  }
}

export function* markModelAsDefaultContent({ payload: model }) {
  try {
    yield call(modelsApi.markModelAsDefaultContent, model.id);
    yield put(loadModelsList());
  } catch (error) {
    console.error(error);
  }
}

export function* unmarkModelAsDefaultContent({ payload: model }) {
  try {
    yield call(modelsApi.unmarkModelAsDefaultContent, model.id);
    yield put(loadModelsList());
  } catch (error) {
    console.error(error);
  }
}

export function* hideModel({ payload: model }) {
  try {
    yield call(modelsApi.hideModel, model.id);
    yield put(loadModelsList());
  } catch (error) {
    console.error(error);
  }
}

export default function* modelsSaga() {
  yield all([
    takeEvery(LOAD_MODELS_LIST, fetchModelsList),
    takeEvery(LOAD_MODELS_COUNTS, fetchModelsCounts),
    takeEvery(LOAD_MODEL_SCENES, fetchModelScenes),
    takeEvery(CREATE_MODEL, fetchPostModel),
    takeEvery(UPDATE_MODEL, fetchPutModel),
    takeEvery(DELETE_MODEL, fetchDeleteModel),
    takeEvery(MARK_MODEL_AS_DEFAULT_CONTENT, markModelAsDefaultContent),
    takeEvery(UNMARK_MODEL_AS_DEFAULT_CONTENT, unmarkModelAsDefaultContent),
    takeEvery(HIDE_MODEL, hideModel),
  ]);
}
