import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@shared/views/form-dialog';
import { updateInterior } from '@store/interiors/actions';
import { ROUTES } from '@app/routes/routesUrls';
import { fillWithInteriorValues, Fieldset } from '../fieldset';
import useNavigate from '@shared/router/useNavigate';
import { useEffect } from 'react';
import { actions as appActions } from '@store/app';

const EditForm = ({
  children,
  className,
  open,
  data,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const { goTo } = useNavigate();
  const apiState = useSelector((state) => state.interiors.state);
  const apiError = useSelector((state) => state.interiors.error);

  const form = useForm({
    defaultValues: fillWithInteriorValues(data),
  });

  const handleSubmit = (newData) => {
    dispatch(updateInterior({
      [Fieldset.Id]: data[Fieldset.Id],
      ...newData,
      [Fieldset.Name]: newData[Fieldset.Name].trim(),
      [Fieldset.Attributes]: newData[Fieldset.Attributes]?.map((attribute) => attribute.id),
    }));
  };

  useEffect(() => {
    if (apiError && apiError.type === 'UPDATE') {
      const titles = apiError.data.message.map((message) => {
        if (message.reason === 'unique') {
          return `Property '${message.property}' should be unique`;
        }
      });
      dispatch(appActions.addMessage({ message: titles, type: 'error' }));
    }
  }, [dispatch, apiError]);

  useEffect(() => {
    if (apiState === 'success') {
      onSubmit();
      goTo(ROUTES.INTERIORS);
    }
  }, [dispatch, apiState]);

  return (
    <Form
      className={ className }
      open={ open }
      onSubmit={ form.handleSubmit(handleSubmit) }
    >
      <FormProvider { ...form }>
        {children}
      </FormProvider>
    </Form>
  );
};

export default EditForm;
