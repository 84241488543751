import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createModel } from '@store/models/actions';
import { Form } from '@shared/views/form-dialog';
import { fillWithDefaultValues, getUnscaledSizes } from '../fieldset';
import { ModelType } from '../../constants';
import { ROUTES } from '@app/routes/routesUrls';
import useNavigate from '@shared/router/useNavigate';
import { useEffect } from 'react';
import { actions as appActions } from '@store/app';

const CreateForm = ({
  children,
  className,
  open,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const { goTo } = useNavigate();
  const apiState = useSelector((state) => state.models.state);
  const apiError = useSelector((state) => state.models.error);

  const form = useForm({
    defaultValues: fillWithDefaultValues(),
  });

  const handleSubmit = async (newData) => {
    dispatch(createModel({
      type: ModelType.ThreeDimensional,
      ...newData,
      ...getUnscaledSizes(newData),
      name: newData.name.trim(),
      attributes: newData.attributes?.map((attr) => attr.id),
    }));
  };

  useEffect(() => {
    if (apiError && apiError.type === 'CREATE') {
      const titles = apiError.data.message.map((message) => {
        if (message.reason === 'unique') {
          return `Property '${message.property}' should be unique`;
        }
      });
      dispatch(appActions.addMessage({ message: titles, type: 'error' }));
    }
  }, [dispatch, apiError]);

  useEffect(() => {
    if (apiState === 'success') {
      onSubmit();
      goTo(ROUTES.MODELS);
    }
  }, [dispatch, apiState]);

  return (
    <Form
      className={ className }
      open={ open }
      onSubmit={ form.handleSubmit(handleSubmit) }
    >
      <FormProvider { ...form }>
        {children}
      </FormProvider>
    </Form>
  );
};

export default CreateForm;
