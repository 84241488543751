import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateVariant } from '@store/products/actions';
import { Form } from '@shared/views/form-dialog';
import { fillWithDefaultValues, fillWithProductValues } from '../fieldset';
import { isVariantExistsInProduct } from '@pages/products/utils';
import { actions as appActions } from '@store/app';
import { useEffect } from 'react';

const VariantForm = ({
  children,
  className,
  open,
  data,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const apiState = useSelector((state) => state.products.state);

  const allEnvironments = useSelector((state) => state.scenes.environments.items);

  const form = useForm({
    defaultValues: {
      ...fillWithDefaultValues({ allEnvironments }),
      ...fillWithProductValues(data),
    },
  });

  const handleSubmit = async (newData) => {
    const hasSuchVariant = isVariantExistsInProduct(newData.materials, data.parentProduct,
      [data.id]);

    if (hasSuchVariant) {
      dispatch(appActions.addMessage({ message: 'Such Variation already exists', type: 'error' }));
      return;
    }

    const result = await onSubmit(newData, false);
    const finalData = result ?? newData;

    dispatch(updateVariant({
      id: data.id,
      ...finalData,
      name: finalData.name.trim(),
      parentProduct: data.parentProduct,
      attributes: finalData.attributes?.map((attr) => attr.id),
    }));
  };

  useEffect(() => {
    if (apiState === 'success') {
      onSubmit(null, true);
    }
  }, [dispatch, apiState]);

  return (
    <Form
      className={ className }
      open={ open }
      onSubmit={ form.handleSubmit(handleSubmit) }
    >
      <FormProvider { ...form }>
        {children}
      </FormProvider>
    </Form>
  );
};

export default VariantForm;
