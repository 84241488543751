import {forwardRef, memo, useCallback, useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import Autocomplete from '@shared/components/autocomplete/Autocomplete';
import { TextField } from '@shared/views/form-dialog';
import UploadIcon from '@resources/icons/UploadIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';
import { deleteEnvironment, loadEnvironmentsList, resetScenesApiState } from '@store/scenes/actions';
import { selectCurrentCompany } from '@store/app/selectors';
import useWarningControl from '@shared/components/card-controls/useWarningControl';
import WarningDialog from '@shared/components/card-controls/WarningDialog';

const useStyles = makeStyles({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: '9px',
      '& button': {
        order: 3,
      },
      '& > div.MuiAutocomplete-endAdornment': {
        position: 'relative',
        order: 2,
      },
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > img': {
      width: '40px',
      height: '40px',
    },
  },
});

const EnvironmentTextField = forwardRef(({
  name,
  label,
  onChange,
  value,
  required,
}, ref) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const apiError = useSelector((state) => state.scenes.error);
  const options = useSelector((state) => state.scenes.environments.items);
  const defaultValue = useMemo(() => ({ name: '', url: '' }), []);
  const company = useSelector(selectCurrentCompany);

  const [warningTitle, setWarningTitle] = useState('');

  const [warningOpen, {
    handleWarningControlClick,
    handleWarningClose,
  }] = useWarningControl();

  useEffect(() => {
    dispatch(loadEnvironmentsList());
  }, [dispatch]);

  useEffect(() => {
    if (apiError && apiError.type === 'DELETE') {
      handleWarningControlClick();
      const titles = apiError.data.message.map((message) => {
        if (message.reason === 'used') {
          const usedIn = message.usedIn.map((p) => p.name).join(', ');
          if (message.location === 'Scene') {
            return `Action cannot be performed because given hdri is used in the following scenes: [${usedIn}]`;
          }
          if (message.location === 'Studio') {
            return `Action cannot be performed because given hdri is used in the following studios: [${usedIn}]`;
          }
        }
      });
      setWarningTitle(titles.join('\n'));
    }
    if (warningOpen && !apiError) {
      handleWarningClose();
    }
  }, [
    warningOpen,
    handleWarningControlClick,
    apiError,
  ]);

  const handleChange = useCallback((_, data) => {
    if (data && data.id) {
      onChange({
        ...data,
      });
    } else {
      onChange({
        ...options.find((op) => op.name === data) || (value && value.name === data ? value : defaultValue),
      });
    }
  }, [onChange, options, value, defaultValue]);

  const getOptionObject = useCallback((option) => ((typeof option === 'string' || option instanceof String)
    ? options.find((op) => op.id === option)
    : option
  ) || defaultValue, [options, defaultValue]);

  const getOptionLabel = useCallback((option) => (
    option ? getOptionObject(option).name : option
  ), [getOptionObject]);

  const getOptionSelected = useCallback((option, value) => (
    value ? option.id === getOptionObject(value).id : value
  ), [getOptionObject]);

  const handleDelete = useCallback((value, event) => {
    event.stopPropagation();
    dispatch(deleteEnvironment(value.id));
  }, [dispatch]);

  const handleUploadEnvironment = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = () => {
      const files = Array.from(input.files);
      const reader = new FileReader();
      const file = files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        const url = window.URL.createObjectURL(file);
        onChange({ url, name: file.name, type: file.name.split('.').pop(), image: file });
      };
    };
    input.click();
  };

  const handleApiErrorClear = () => {
    dispatch(resetScenesApiState());
  };

  return (
    <>
      <Autocomplete
        freeSolo
        options={ options }
        getOptionLabel={ getOptionLabel }
        getOptionSelected={ getOptionSelected }
        onChange={ handleChange }
        onBlur={ (e) => handleChange(e, e.target.value) }
        ref={ ref }
        value={ value || defaultValue }
        renderInput={ (params) => (
          <TextField
            { ...params }
            classes={ {
              root: classes.textFieldRoot,
            } }
            label={ label }
            variant="outlined"
            required={ required }
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <>
                  <IconButton size="small" onClick={ handleUploadEnvironment }>
                    <UploadIcon />
                  </IconButton>
                  {params.InputProps.endAdornment}
                </>
              ),
            } }
          />
        ) }
        renderOption={ (props, { selected }) => (
          <div className={ classNames({ selected }, classes.option) }>
            { props.name }
            <IconButton
              disabled={
                        company.default
                          ? !!props.predefined
                          : props.defaultContent
                      }
              size="small"
              onClick={ handleDelete.bind(null, props) }
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ) }
      />
      <WarningDialog
        open={ warningOpen }
        title={ warningTitle }
        onClose={ handleApiErrorClear }
      />
    </>
  );
});

export default memo(EnvironmentTextField);
