import {
  all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { studiosApi } from '@api';
import {
  LOAD_STUDIOS_LIST,
  LOAD_STUDIOS_COUNTS,
  CREATE_STUDIO,
  UPDATE_STUDIO,
  DELETE_STUDIO,
  LOAD_STUDIO,
  loadStudiosList,
  renderStudiosList,
  renderStudiosCounts,
  renderStudio,
} from './actions';
import { selectCurrentCompany } from '@store/app/selectors';
import addHiddenStateForItems from '@shared/utils/addHiddenStateForItems';

export function* fetchStudiosList() {
  try {
    const data = yield call(studiosApi.getStudios);
    const company = yield select(selectCurrentCompany);
    yield put(renderStudiosList(addHiddenStateForItems(data, company)));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchStudio({ id }) {
  try {
    const data = yield call(studiosApi.getStudio, id);
    yield put(renderStudio(data));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchStudiosCounts() {
  try {
    const data = yield call(studiosApi.getStudiosCounts);
    yield put(renderStudiosCounts(data));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchPostStudio({ studio }) {
  try {
    const data = yield call(studiosApi.createStudio, studio);
    yield put(renderStudio({
      ...data,
      ...studio,
    }));
    yield put(loadStudiosList());
  } catch (error) {
    console.error(error);
  }
}

export function* fetchPutStudio({ studio }) {
  try {
    const data = yield call(studiosApi.editStudio, studio);
    yield put(renderStudio({
      ...data,
      ...studio,
    }));
    yield put(loadStudiosList());
  } catch (error) {
    console.error(error);
  }
}

export function* fetchDeleteStudio({ id }) {
  try {
    yield call(studiosApi.deleteStudio, id);
    yield put(loadStudiosList());
  } catch (error) {
    console.error(error);
  }
}

export default function* studiosSaga() {
  yield all([
    takeEvery(LOAD_STUDIOS_LIST, fetchStudiosList),
    takeEvery(LOAD_STUDIOS_COUNTS, fetchStudiosCounts),
    takeEvery(CREATE_STUDIO, fetchPostStudio),
    takeEvery(UPDATE_STUDIO, fetchPutStudio),
    takeEvery(DELETE_STUDIO, fetchDeleteStudio),
    takeEvery(LOAD_STUDIO, fetchStudio),
  ]);
}
