import { makeStyles } from '@material-ui/core/styles';
import { FormDialog } from '@shared/views/form-dialog';
import Form from './Form';
import FormFieldset from '../FormFieldset';
import { useDispatch, useSelector } from 'react-redux';
import { resetMaterialApiState } from '@store/materials/actions';

const useStyles = makeStyles(() => ({
  productDrawerPaper: {
    maxWidth: '1019px',
    width: '100%',

    '& .MuiDialogContent-root': {
      padding: 0,
      overflow: 'auto',
    },
  },
}));

const CopyFormDialog = ({
  open,
  onClose,
  data,
}) => {
  const apiState = useSelector((state) => state.materials.state);

  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(resetMaterialApiState());
    onClose();
  };

  const handleCancel = () => {
    dispatch(resetMaterialApiState());
    onClose();
  };

  return (
    <FormDialog
      open={ open }
      Form={ Form }
      FormProps={ {
        open,
        onSubmit: handleSubmit,
        data,
      } }
      title="Copy Material"
      content={ <FormFieldset /> }
      onCancel={ handleCancel }
      onClose={ onClose }
      maxWidth="sm"
      titleAlign="center"
      drawerPaperProps={ { className: classes.productDrawerPaper } }
      isDrawer
      isSubmitDisabled={ apiState === 'processing' }
    />
  );
};

export default CopyFormDialog;
