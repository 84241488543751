import RangeSlider from '../RangeSlider';

const IntensityOption = ({
  name,
  value,
  onChange,
}) => (
  <RangeSlider
    label="Light Intensity"
    name={ name }
    min={ 0 }
    max={ 100 }
    value={ value }
    onChange={ onChange }
    displayValues={ false }
    displayThumbValue
  />
);

export default IntensityOption;
