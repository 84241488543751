import { RESET_MODEL_API_STATE } from '@store/models/actions';

export const LOAD_PRODUCTS_LIST = 'LOAD_PRODUCTS_LIST';
export const RENDER_PRODUCTS_LIST = 'RENDER_PRODUCTS_LIST';
export const LOAD_PRODUCTS_COUNTS = 'LOAD_PRODUCTS_COUNTS';
export const RENDER_PRODUCTS_COUNTS = 'RENDER_PRODUCTS_COUNTS';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';
export const RENDER_PRODUCT = 'RENDER_PRODUCT';

export const CREATE_VARIANT = 'CREATE_VARIANT';
export const CREATE_VARIANT_SUCCESS = 'CREATE_VARIANT_SUCCESS';
export const CREATE_VARIANT_ERROR = 'CREATE_VARIANT_ERROR';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const UPDATE_VARIANT_SUCCESS = 'UPDATE_VARIANT_SUCCESS';
export const UPDATE_VARIANT_ERROR = 'UPDATE_VARIANT_ERROR';
export const DELETE_VARIANT = 'DELETE_VARIANT';
export const DELETE_VARIANT_SUCCESS = 'DELETE_VARIANT_SUCCESS';

export const SORTED_ID = 'SORTED_ID';
export const MARK_PRODUCT_AS_DEFAULT_CONTENT = 'MARK_PRODUCT_AS_DEFAULT_CONTENT';
export const UNMARK_PRODUCT_AS_DEFAULT_CONTENT = 'UNMARK_PRODUCT_AS_DEFAULT_CONTENT';
export const HIDE_PRODUCT = 'HIDE_PRODUCT';
export const RESET_PRODUCT_API_STATE = 'RESET_PRODUCT_API_STATE';

export function loadProductsList() {
  return {
    type: LOAD_PRODUCTS_LIST,
  };
}

export function renderProductsList(products) {
  return {
    type: RENDER_PRODUCTS_LIST,
    products,
  };
}

export function loadProductsCounts() {
  return {
    type: LOAD_PRODUCTS_COUNTS,
  };
}

export function renderProductsCounts(counts) {
  return {
    type: RENDER_PRODUCTS_COUNTS,
    payload: counts,
  };
}

export function resetProducts() {
  return {
    type: RESET_PRODUCTS,
  };
}

export function createProduct(product) {
  return {
    type: CREATE_PRODUCT,
    product,
  };
}

export function createProductError(error) {
  return {
    type: CREATE_PRODUCT_ERROR,
    error,
  };
}

export function createProductSuccess() {
  return {
    type: CREATE_PRODUCT_SUCCESS,
  };
}

export function renderProduct(product) {
  return {
    type: RENDER_PRODUCT,
    product,
  };
}

export function updateProduct(product) {
  return {
    type: UPDATE_PRODUCT,
    product,
  };
}

export function updateProductError(error) {
  return {
    type: UPDATE_PRODUCT_ERROR,
    error,
  };
}

export function updateProductSuccess() {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
  };
}

export function deleteProduct(id) {
  return {
    type: DELETE_PRODUCT,
    id,
  };
}

export function deleteProductError(id, error) {
  return {
    type: DELETE_PRODUCT_ERROR,
    id,
    error,
  };
}

export function createVariant(variant) {
  return {
    type: CREATE_VARIANT,
    variant,
  };
}

export function createVariantError(error) {
  return {
    type: CREATE_VARIANT_ERROR,
    error,
  };
}

export function createVariantSuccess(variant) {
  return {
    type: CREATE_VARIANT_SUCCESS,
    variant,
  };
}

export function updateVariant(variant) {
  return {
    type: UPDATE_VARIANT,
    variant,
  };
}

export function updateVariantError(error) {
  return {
    type: UPDATE_VARIANT_ERROR,
    error,
  };
}

export function updateVariantSuccess(variant) {
  return {
    type: UPDATE_VARIANT_SUCCESS,
    variant,
  };
}

export function deleteVariant(id, parentProductId) {
  return {
    type: DELETE_VARIANT,
    id,
    parentProductId,
  };
}

export function deleteVariantSuccess(id, parentProductId) {
  return {
    type: DELETE_VARIANT_SUCCESS,
    id,
    parentProductId,
  };
}

export function sortedId(value) {
  return {
    type: SORTED_ID,
    value,
  };
}

export const markProductAsDefaultContent = (model) => ({
  type: MARK_PRODUCT_AS_DEFAULT_CONTENT,
  payload: model,
});

export const unmarkProductAsDefaultContent = (model) => ({
  type: UNMARK_PRODUCT_AS_DEFAULT_CONTENT,
  payload: model,
});

export const hideProduct = (model) => ({
  type: HIDE_PRODUCT,
  payload: model,
});

export function resetProductApiState() {
  return {
    type: RESET_PRODUCT_API_STATE,
  };
}
