import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Buffer } from 'buffer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { downloadZip } from 'client-zip';
// eslint-disable-next-line import/no-extraneous-dependencies
import FileSaver from 'file-saver';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { SketchPicker } from 'react-color';
import { useMaterials } from '@zolak/zolak-viewer';

import {
  TextField,
  Fields,
  Field,
  GroupField,
} from '@shared/views/form-dialog';
import Control from '@shared/components/card-controls/Control';
import DeleteIcon from '@resources/icons/DeleteIcon';
import ModelTextField from './ModelTextField';
import PresetTextField from './PresetTextField';
import ShadowsTextField from './ShadowsTextField';
import RangeSlider from '../scenes/child-details/LightDetails/RangeSlider';
import Button from '@shared/components/button';
import { materialsSelector } from '@store/materials/selectors';
import { createStudio, updateStudio } from '@store/studios/actions';
import { selectStudio } from '@store/studios/selectors';
import StudioViewer from './StudioViewer';
import SelectMaterialsFormDialog from './modal/select/MaterialsFormDialog';
import { Vector2 } from 'three';
import EnvironmentTextField from '@pages/scenes/child-details/SceneDetails/EnvironmentTextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import CombinationTextField from '@pages/studio/CombinationTextField';
import ToneMappingTextField from '@pages/studio/ToneMappingTextField';
import ColorOption from '@pages/scenes/child-details/LightDetails/lightOptions/ColorOption';
import KelvinColor from '@shared/utils/KelvinColor';
import useFormValues from '@shared/hooks/useFormValues';

function dataUrlToFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  if (arr.length < 2) { return undefined; }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) { return undefined; }
  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], 'base64');
  return new File([buff], filename, { type: mime });
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '100%',
  },
  sidePanel: {
    flex: '0 0 300px',
    padding: '8px',
    overflow: 'auto',
  },
  content: {
    flex: '1 1 auto',
    position: 'relative',
  },
  topBar: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    right: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    zIndex: 2,
    gap: '8px',
  },
  camerasToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addButton: {
    width: 'auto',
    padding: '1.1rem 2rem',
  },
  item: {
    fontSize: '14px',
    padding: '8px 0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover, &.selected': {
      color: '#00C98F',
    },
  },
  generating: {
    position: 'absolute',
    inset: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.2)',
    zIndex: '10',
  },
  generatingContent: {
    width: '50%',
    height: '30px',
    margin: 'auto',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  generateButton: {
    marginTop: '10px',
    minWidth: '100%',
  },
  saveButton: {
    marginLeft: 'auto',
  },
  combination: {
    minWidth: '300px',
  },
}));

function Studio() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [renders, setRenders] = useState([]);
  const [queue, setQueue] = useState([]);
  const [queueSize, setQueueSize] = useState([]);
  const [currentCamera, setCurrentCamera] = useState();
  const [currentMaterials, setCurrentMaterials] = useState([]);
  const [currentCombination, setCurrentCombination] = useState([]);
  const [actionName, setActionName] = useState(null);
  const data = useSelector(selectStudio);
  const materials = useSelector(materialsSelector);
  const threeRef = useRef();

  const form = useForm({
    defaultValues: data || {
      name: '',
      presets: 'rembrandt',
      environment: 'apartment',
      intensity: 0.5,
      envIntensity: 1,
      shadows: 'contact',
      cameras: [],
      materials: [],
    },
  });
  const formValues = useFormValues(form);
  const { isDirty, isValid } = form.formState;

  const groupsCombinations = useMemo(() => {
    const result = [];

    if (!materials.length) {
      return result;
    }

    const allGroups = formValues.materials.find((g) => g.group === 'All Groups');
    const otherGroups = formValues.materials.filter((g) => g.group !== 'All Groups');
    if (allGroups) {
      allGroups.materials.forEach((materialId, materialIndex) => {
        const material = materials.find((m) => m.id === materialId);
        result.push({
          materials: formValues.model.materials.map((m) => ({
            ...m,
            material,
          })),
          groupName: `All Groups Material ${materialIndex + 1}`,
          width: formValues.width,
          height: formValues.height,
        });
      });
    }
    function cartesian(...groups) {
      const r = [];
      const max = groups.length - 1;
      function helper(arr, i) {
        const group = groups[i];
        if (!group) {
          return;
        }
        if (!group.materials.length) {
          r.push(arr);
          helper(arr.slice(0), i + 1);
          return;
        }
        for (let j = 0, l = group.materials.length; j < l; j++) {
          const a = arr.slice(0); // clone arr
          a.push({
            group: group.group,
            material: group.materials[j],
          });
          if (i === max) {
            r.push(a);
          } else {
            helper(a, i + 1);
          }
        }
      }
      helper([], 0);
      return r;
    }
    if (otherGroups.length) {
      const combinations = cartesian(...otherGroups);
      combinations.forEach((combination, combIndex) => {
        result.push({
          materials: formValues.model.materials.map((m) => {
            const materialId = combination.find((c) => c.group === m.group?.id)?.material;
            const material = materials.find((m) => m.id === materialId);
            return {
              ...m,
              material: material ?? m.material,
            };
          }),
          groupName: `Combination ${combIndex + 1}`,
          width: formValues.width,
          height: formValues.height,
        });
      });
    }
    return result;
  }, [materials, formValues.materials, formValues.model, formValues.width, formValues.height]);

  const allCombinations = useMemo(() => {
    const result = [];
    formValues.cameras.forEach((camera, index) => {
      groupsCombinations.forEach((groupsCombination) => {
        result.push({
          ...groupsCombination,
          camera,
          cameraName: `Camera ${index + 1} ${groupsCombination.groupName}`,
        });
      });
    });
    return result;
  }, [formValues.cameras, groupsCombinations]);

  useEffect(() => {
    form.reset(data);
    if (data && data.cameras) {
      setCurrentCamera(data.cameras[0]);
    }
  }, [data]);

  useEffect(() => {
    if (isGenerating && queue.length) {
      setCurrentMaterials(queue[0].materials.map((m) => ({
        ...m,
      })));
      return;
    }
    if (isGenerating && !queue.length) {
      setIsGenerating(false);
    }
  }, [queue, isGenerating]);

  useEffect(() => {
    if (!isGenerating && renders.length) {
      (async () => {
        const content = await downloadZip(renders).blob();
        FileSaver.saveAs(content, 'renders.zip');
        setRenders([]);
      })();
    }
  }, [isGenerating, renders]);

  useEffect(() => {
    if (threeRef.current && threeRef.current.controls) {
      setCurrentCamera({
        position: threeRef.current.camera.position.toArray(),
        rotation: threeRef.current.camera.rotation.toArray(),
        target: threeRef.current.controls.target.toArray(),
      });
    }
  }, [formValues.environment, formValues.model?.model]);

  useEffect(() => {
    if (groupsCombinations.length) {
      setCurrentCombination(groupsCombinations[0]);
      if (threeRef.current && threeRef.current.controls) {
        setCurrentCamera({
          position: threeRef.current.camera.position.toArray(),
          rotation: threeRef.current.camera.rotation.toArray(),
          target: threeRef.current.controls.target.toArray(),
        });
      }
      setCurrentMaterials(groupsCombinations[0].materials);
    }
  }, [groupsCombinations]);

  const handleGenerate = () => {
    allCombinations.forEach((combination) => {
      const model = formValues.model.model;
      useMaterials.clear((model.data || model.blob), 0, combination.materials);
    });
    setQueue(allCombinations);
    setQueueSize(allCombinations.length);
    setIsGenerating(true);
  };

  const handleMaterialsLoad = useCallback(() => {
    setQueue((queue) => {
      if (!queue.length) {
        return queue;
      }
      handleSetCamera(queue[0].camera);
      const originalSize = new Vector2();
      const originalAspect = threeRef.current.camera.aspect;
      threeRef.current.gl.getSize(originalSize);
      threeRef.current.camera.aspect = queue[0].width / queue[0].height;
      threeRef.current.camera.updateProjectionMatrix();
      threeRef.current.gl.setSize(queue[0].width, queue[0].height);
      threeRef.current.gl.render(threeRef.current.scene, threeRef.current.camera);
      const screenShot = threeRef.current.gl.domElement.toDataURL();
      // const names = queue[0].materials.map((m) => m.material?.name).filter((n) => n);
      setRenders((renders) => [...renders, {
        name: `Render ${queue[0].cameraName}.png`,
        lastModified: new Date(),
        input: dataUrlToFile(screenShot),
      }]);
      threeRef.current.camera.aspect = originalAspect;
      threeRef.current.camera.updateProjectionMatrix();
      threeRef.current.gl.setSize(originalSize.x, originalSize.y);
      threeRef.current.gl.render(threeRef.current.scene, threeRef.current.camera);
      return queue.slice(1);
    });
  }, []);

  const handleSaveCamera = () => {
    const newCamera = {
      position: threeRef.current.camera.position.toArray(),
      rotation: threeRef.current.camera.rotation.toArray(),
      target: threeRef.current.controls.target.toArray(),
    };
    setCurrentCamera(newCamera);
    form.setValue('cameras', [...formValues.cameras, newCamera], {
      shouldDirty: true,
    });
  };

  const handleSetCamera = (camera) => {
    if (camera) {
      threeRef.current.camera.position.fromArray(camera.position);
      threeRef.current.camera.rotation.fromArray(camera.rotation);
      threeRef.current.controls.target.fromArray(camera.target);
      threeRef.current.controls.update();
    }
    setCurrentCamera(camera);
  };

  const handleRemoveCamera = (camera, event) => {
    event.stopPropagation();
    const newCameras = formValues.cameras.filter((c) => c !== camera);
    form.setValue('cameras', [...newCameras], {
      shouldDirty: true,
    });
    handleSetCamera(newCameras[0]);
  };

  const handleSave = () => {
    form.handleSubmit(() => {
      if (data.id) {
        dispatch(updateStudio({
          ...formValues,
          id: data.id,
          thumbnail: threeRef.current.gl.domElement.toDataURL(),
        }));
        return;
      }
      dispatch(createStudio({
        ...formValues,
        thumbnail: threeRef.current.gl.domElement.toDataURL(),
      }));
    }, (e) => {
      console.log(e);
    })();
  };

  const handleSelectMaterials = () => {
    setActionName('Materials');
  };

  const handleSelectMaterialFormClose = (data) => {
    if (data && data.materials) {
      form.setValue('materials', data.materials, {
        shouldDirty: true,
      });
    }
    setActionName('Idle');
  };

  return (
    <div className={ classes.container }>
      <div className={ classes.sidePanel }>
        <FormProvider { ...form }>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls="general-content"
              id="general-header"
            >
              <Typography variant="h5">General Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Fields>
                <Field style={ { position: 'relative' } }>
                  <Controller
                    name="name"
                    control={ form.control }
                    defaultValue=""
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <TextField
                        { ...field }
                        label="Name"
                        required
                      />
                    ) }
                  />
                </Field>
                <Field>
                  <Controller
                    name="background"
                    defaultValue="#ffffff"
                    render={ ({ field }) => (
                      <>
                        <Button onClick={ () => setDisplayColorPicker(true) }>
                          Select Background
                        </Button>
                        {
                          displayColorPicker ? (
                            <div className={ classes.popover }>
                              <div className={ classes.cover } onClick={ () => setDisplayColorPicker(false) } />
                              <SketchPicker
                                color={ field.value }
                                onChange={ (value) => {
                                  field.onChange(value.hex);
                                } }
                              />
                            </div>
                          ) : null
                        }
                      </>
                    ) }
                    control={ form.control }
                  />
                </Field>
              </Fields>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls="model-content"
              id="model-header"
            >
              <Typography variant="h5">Model & Materials</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Fields>
                <Field style={ { position: 'relative' } }>
                  <Controller
                    name="model"
                    control={ form.control }
                    defaultValue={ null }
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <ModelTextField
                        { ...field }
                        label="Model"
                        required
                      />
                    ) }
                  />
                </Field>
                <Field>
                  <Controller
                    name="materials"
                    render={ () => (
                      <>
                        <div className={ classes.camerasToolbar }>
                          <h5 className="MuiTypography-h5">Materials</h5>
                          <Button onClick={ handleSelectMaterials } disabled={ !formValues.model }>
                            Select ({
                            formValues.materials
                              ?.map((m) => m.materials.length)
                              .reduce((acc, v) => (acc += v), 0)
                            || '0'
                          })
                          </Button>
                          <SelectMaterialsFormDialog
                            open={ actionName === 'Materials' }
                            data={ formValues }
                            onClose={ handleSelectMaterialFormClose }
                          />
                        </div>
                      </>
                    ) }
                    control={ form.control }
                  />
                </Field>
              </Fields>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls="lights-content"
              id="lights-header"
            >
              <Typography variant="h5">HDRi & Lights</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Fields>
                <Field style={ { position: 'relative' } }>
                  <Controller
                    name="toneMapping"
                    control={ form.control }
                    defaultValue="NeutralToneMapping"
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <ToneMappingTextField
                        { ...field }
                        label="Tone Mapping"
                        required
                      />
                    ) }
                  />
                </Field>
                <Field>
                  <Controller
                    name="toneMappingExposure"
                    defaultValue="1"
                    render={ ({ field }) => (
                      <RangeSlider
                        label="Tone Mapping Exposure"
                        min={ 0 }
                        max={ 10 }
                        displayValues={ false }
                        displayThumbValue
                        debounce={ 100 }
                        { ...field }
                        onChange={ ({ value }) => {
                          field.onChange(value);
                        } }
                      />
                    ) }
                    control={ form.control }
                  />
                </Field>
                <Field>
                  <Controller
                    name="ambient"
                    defaultValue={ new KelvinColor('ffffff') }
                    render={ ({ field }) => (
                      <ColorOption
                        name="ambient"
                        label="Ambient Light Color"
                        { ...field }
                        onChange={ ({ value }) => {
                          field.onChange(value);
                        } }
                      />
                    ) }
                    control={ form.control }
                  />
                </Field>
                <Field>
                  <Controller
                    name="ambientIntensity"
                    defaultValue="1"
                    render={ ({ field }) => (
                      <RangeSlider
                        label="Ambient Light Intensity"
                        min={ 0 }
                        max={ 10 }
                        displayValues={ false }
                        displayThumbValue
                        debounce={ 100 }
                        { ...field }
                        onChange={ ({ value }) => {
                          field.onChange(value);
                        } }
                      />
                    ) }
                    control={ form.control }
                  />
                </Field>
                <Field style={ { position: 'relative' } }>
                  <Controller
                    name="environment"
                    control={ form.control }
                    defaultValue={ null }
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <EnvironmentTextField
                        { ...field }
                        label="Environment"
                        required
                      />
                    ) }
                  />
                </Field>
                <Field>
                  <Controller
                    name="envIntensity"
                    defaultValue="1"
                    render={ ({ field }) => (
                      <RangeSlider
                        label="HDR Brightness"
                        min={ 0 }
                        max={ 10 }
                        displayValues={ false }
                        displayThumbValue
                        debounce={ 100 }
                        { ...field }
                        onChange={ ({ value }) => {
                          field.onChange(value);
                        } }
                      />
                    ) }
                    control={ form.control }
                  />
                </Field>
                <Field style={ { position: 'relative' } }>
                  <Controller
                    name="presets"
                    control={ form.control }
                    defaultValue="rembrandt"
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <PresetTextField
                        { ...field }
                        label="Light Preset"
                        required
                      />
                    ) }
                  />
                </Field>
                <Field>
                  <Controller
                    name="intensity"
                    render={ ({ field }) => (
                      <RangeSlider
                        label="Light Intensity"
                        min={ 0 }
                        max={ 10 }
                        displayValues={ false }
                        displayThumbValue
                        debounce={ 100 }
                        { ...field }
                        onChange={ ({ value }) => {
                          field.onChange(value);
                        } }
                      />
                    ) }
                    control={ form.control }
                  />
                </Field>
                <Field style={ { position: 'relative' } }>
                  <Controller
                    name="shadows"
                    control={ form.control }
                    defaultValue="contact"
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <ShadowsTextField
                        { ...field }
                        label="Shadows"
                        required
                      />
                    ) }
                  />
                </Field>
              </Fields>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls="render-content"
              id="render-header"
            >
              <Typography variant="h5">Render Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Fields>
                <Field>
                  <Controller
                    name="cameras"
                    render={ () => (
                      <>
                        <div className={ classes.camerasToolbar }>
                          <h5 className="MuiTypography-h5">Cameras</h5>
                          <Button className={ classes.addButton } onClick={ handleSaveCamera }>
                            Add
                          </Button>
                        </div>
                        {
                          formValues.cameras.map((camera, index) => (
                            <div
                              key={ index }
                              className={ classNames(classes.item, { selected: currentCamera === camera }) }
                              onClick={ handleSetCamera.bind(null, camera) }
                            >
                              Camera {index + 1}
                              <Control
                                label="Delete"
                                onClick={ handleRemoveCamera.bind(null, camera) }
                              >
                                <DeleteIcon />
                              </Control>
                            </div>
                          ))
                        }
                      </>
                    ) }
                    control={ form.control }
                  />
                </Field>
                <GroupField>
                  <Controller
                    name="width"
                    control={ form.control }
                    defaultValue="3840"
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <TextField
                        { ...field }
                        label="Output Width"
                        type="number"
                        required
                      />
                    ) }
                  />
                  <Controller
                    name="height"
                    control={ form.control }
                    defaultValue="2160"
                    rules={ {
                      required: 'Required',
                    } }
                    render={ ({ field }) => (
                      <TextField
                        { ...field }
                        label="Output Height"
                        type="number"
                        required
                      />
                    ) }
                  />
                </GroupField>
              </Fields>
            </AccordionDetails>
          </Accordion>
          <Fields>
            <Field>
              <Button
                className={ classes.generateButton }
                onClick={ handleGenerate }
                disabled={ !allCombinations.length }
              >
                Generate
              </Button>
            </Field>
          </Fields>
        </FormProvider>
      </div>
      <div className={ classes.content }>
        <div className={ classes.topBar }>
          {
            !!groupsCombinations.length && (
            <CombinationTextField
              className={ classes.combination }
              label="Combinations"
              options={ groupsCombinations }
              value={ currentCombination }
              defaultValue={ groupsCombinations[0] }
              onChange={ (value) => {
                setCurrentCombination(value);
                setCurrentCamera({
                  position: threeRef.current.camera.position.toArray(),
                  rotation: threeRef.current.camera.rotation.toArray(),
                  target: threeRef.current.controls.target.toArray(),
                });
                setCurrentMaterials(value.materials);
              } }
            />
            )
          }
          <Button className={ classes.saveButton } onClick={ handleSave } disabled={ !isDirty || !isValid }>
            Save
          </Button>
        </div>
        {
          formValues.model && formValues.model.model && (
            <StudioViewer
              ref={ threeRef }
              background={ formValues.background }
              presets={ formValues.presets }
              toneMapping={ formValues.toneMapping }
              toneMappingExposure={ formValues.toneMappingExposure }
              environment={ formValues.environment }
              envIntensity={ formValues.envIntensity }
              ambient={ formValues.ambient }
              ambientIntensity={ formValues.ambientIntensity }
              intensity={ formValues.intensity }
              shadows={ formValues.shadows }
              value={ formValues.model.model }
              camera={ currentCamera }
              materials={ currentMaterials.length ? currentMaterials : formValues.model.materials }
              onMaterialsLoad={ handleMaterialsLoad }
            />
          )
        }
      </div>
      {
        isGenerating && (
          <div className={ classes.generating }>
            <LinearProgress className={ classes.generatingContent } variant="determinate" value={ 100 - (queue.length * 100) / queueSize } />
          </div>
        )
      }
    </div>
  );
}

export default Studio;
