import { DefaultLightPresetsData } from '@pages/products/constants';

export const Fieldset = {
  Name: 'name',
  ExternalId: 'externalId',
  Price: 'price',
  Currency: 'currency',
  Description: 'description',
  Model: 'model',
  ModelData: 'model.data',
  Materials: 'materials',
  Attributes: 'attributes',
  Thumbnail: 'thumbnail',
  Environment: 'environment',
  TextureSize: 'textureSize',
  TranslatedFields: 'translatedFields',
};

export const scaleParameter = (value, scale) => value * scale;

export const getUnscaledSizes = (data) => ({
  [Fieldset.Width]: data[Fieldset.Width] / data[Fieldset.Scale][0],
  [Fieldset.Height]: data[Fieldset.Height] / data[Fieldset.Scale][1],
  [Fieldset.Length]: data[Fieldset.Length] / data[Fieldset.Scale][2],
});

export const getDefaultLightPreset = (allEnvironments) => {
  let result = null;
  const targetItem = allEnvironments?.find((item) => item.uid === DefaultLightPresetsData[1].uid);

  if (targetItem) {
    result = {
      ...targetItem,
      ...DefaultLightPresetsData[1],
    };
  }

  return result;
};

// TODO: do we really need this function? We just need an NEW object for default values
export const fillWithDefaultValues = ({ allEnvironments = [] } = {}) => ({
  [Fieldset.Name]: '',
  [Fieldset.ExternalId]: '',
  [Fieldset.Price]: '',
  [Fieldset.Currency]: '',
  [Fieldset.Description]: '',
  [Fieldset.Model]: {
    data: {
      blob: '',
      scale: [1, 1, 1],
    },
  },
  [Fieldset.Materials]: [],
  [Fieldset.Attributes]: [],
  [Fieldset.Thumbnail]: '',
  [Fieldset.Environment]: getDefaultLightPreset(allEnvironments),
  [Fieldset.TextureSize]: 1024,
  [Fieldset.TranslatedFields]: {},
});

export const fillWithProductValues = (model) => ({
  ...Object.entries(model).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      acc[key] = value;
    }

    return acc;
  }, {}),
  [Fieldset.Materials]: model[Fieldset.Materials]?.map((item) => ({
    uvScaleX: 1,
    uvScaleY: 1,
    uvOffsetX: 0,
    uvOffsetY: 0,
    rotation: 0,
    ...item,
    group: item.group ? item.group : {
      id: undefined,
      name: 'Non-configurable Part',
    },
  })) ?? [],
  [Fieldset.Model]: {
    ...model?.[Fieldset.Model],
    data: {
      blob: model?.[Fieldset.Model]?.data,
      thumbnail: model?.[Fieldset.Model]?.data?.thumbnail,
      dataType: model?.[Fieldset.Model]?.dataType,
      scale: model?.[Fieldset.Model]?.scale,
    },
  },
});

export const InputLabelProps = {
  shrink: true,
};
