import { formatSize } from '@shared/utils';

export const Fieldset = {
  Name: 'name',
  ExternalId: 'externalId',
  Data: 'data',
  Width: 'width',
  Height: 'height',
  Length: 'length',
  Scale: 'scale',
  DataType: 'dataType',
  Attributes: 'attributes',
};

export const DEFAULT_SCALE = 1;
export const MIN_SIZE_FIELD_VALUE = 1;

export const scaleParameter = (value, scale) => value * scale;

export const SCALE_TRESHOLD_PERCENT = 5;

export const scaleInRange = (newValue) => Math.abs(100 - newValue * 100 / DEFAULT_SCALE) < SCALE_TRESHOLD_PERCENT;

export const getUnscaledSizes = (data) => ({
  [Fieldset.Width]: data[Fieldset.Width] / data[Fieldset.Scale][0],
  [Fieldset.Height]: data[Fieldset.Height] / data[Fieldset.Scale][1],
  [Fieldset.Length]: data[Fieldset.Length] / data[Fieldset.Scale][2],
});

// TODO: do we really need this function? We just need an NEW object for default values
export const fillWithDefaultValues = () => ({
  [Fieldset.Name]: '',
  [Fieldset.Data]: {
    blob: '',
  },
  [Fieldset.Width]: '',
  [Fieldset.Height]: '',
  [Fieldset.Length]: '',
  [Fieldset.Scale]: [DEFAULT_SCALE, DEFAULT_SCALE, DEFAULT_SCALE],
  [Fieldset.Attributes]: [],
});

export const fillWithModelValues = (model) => ({
  ...Object.entries(model).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }

    return acc;
  }, {}),
  [Fieldset.Data]: {
    blob: model[Fieldset.Data],
    thumbnail: model.thumbnail,
    dataType: model[Fieldset.DataType],
  },
  [Fieldset.Width]: formatSize(scaleParameter(+model[Fieldset.Width], model[Fieldset.Scale][0] || 1)),
  [Fieldset.Height]: formatSize(scaleParameter(+model[Fieldset.Height], model[Fieldset.Scale][1] || 1)),
  [Fieldset.Length]: formatSize(scaleParameter(+model[Fieldset.Length], model[Fieldset.Scale][2] || 1)),
});

export const InputLabelProps = {
  shrink: true,
};
