import { useRef, useEffect, useMemo, memo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, Stage } from '@react-three/drei';
import useLoadObject from './hooks/useLoadObject';

const Scene = ({ value, materials, environment, scale, size, onLoad, onFrame }) => {
  const gl = useThree((state) => state.gl);
  const camera = useThree((state) => state.camera);
  const scene = useThree((state) => state.scene);

  const controlsRef = useRef();
  const object = useLoadObject(value, materials, value.scale || scale, size, (object) => {
    onLoad(object, camera, gl, scene);
  });

  const envConfig = useMemo(() => (environment ? {
    files: `${environment.url}?name=${environment.name.replace(' ', '_')}.${environment.type}`,
  } : null), [environment]);

  useFrame(() => {
    onFrame && onFrame(object);
  });

  return (
    <>
      <OrbitControls ref={ controlsRef } rotateSpeed={ 1.5 } makeDefault />
      <Stage
        key={ value?.blob }
        adjustCamera={ 1.25 }
        intensity={ 0.5 }
        shadows="contact"
        environment={ envConfig }
      >
        <primitive object={ object } scale={ value.scale || scale } />
      </Stage>
    </>
  );
};

export default memo(Scene);
