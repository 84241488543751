import { FormDialog } from '@shared/views/form-dialog';
import Form from './Form';
import FormFieldset from '../FormFieldset';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { resetInteriorApiState } from '@store/interiors/actions';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: '1250px',
    maxWidth: '66%',
  },
}));

const CreateFormDialog = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(resetInteriorApiState());
    onClose();
  };

  const handleCancel = () => {
    dispatch(resetInteriorApiState());
    onClose();
  };

  return (
    <FormDialog
      open={ open }
      Form={ Form }
      FormProps={ {
        open,
        onSubmit: handleSubmit,
      } }
      title="Add New Background"
      content={ <FormFieldset isCreate /> }
      onCancel={ handleCancel }
      onClose={ onClose }
      maxWidth="sm"
      titleAlign="center"
      isDrawer
      drawerPaperProps={ { className: classes.drawerPaper } }
    />
  );
};

export default CreateFormDialog;
